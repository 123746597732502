.login-body {
    width: 440px;
    max-width: 95%;
    padding-top: 50px;
    margin: 0 auto;
    color: white;
}
.login-form h1 {
    color: #000;
}
.login-form {
    padding: 20px;
    background: rgba(226, 223, 255, 0.85);
    color: #74767d;
}