.hov:hover,
.contrItem:hover {
    background: rgba(211, 211, 211, 0.25);
}

.userPeriodItemCh {
    width: 50%;
    display: inline-block;
}

.userPeriodItem {
    width: 15%;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
}

.headWrap {
    font-size: 10px;
    font-weight: bold;
}

.newListInp {
    width: 20%;
    display: inline-block;
}

.newListInp.short {
    width: 15%;
}

.col-xs-0 {
    display: none!important;
}

.newListInp.acc {
    width: 65%;
}

.fieldErr {
    background: rgba(255, 0, 0, 0.27);
}
.border {
    border: 1px solid grey;
}
.tbodyStats td, .tbodyStats th {
    padding: 2px 10px!important;
    color: #3b3b3b !important;
    font-size: 12px!important;
}
.tbodyStats th {
    padding: 8px 10px!important;
}

.zpTitle {
    display: inline-block;
    font-weight: bold;
    width: 65px!important;
    font-size: 12px;
}
.paddHov {
    padding: 3px 7px;
}

[data-account-key='total'], [data-type-key='total'],  [data-type-key='prihod'], [data-type-key='raspredi'], [data-type-key='pribil'] {
    font-weight: bold;
}

.rubDetails {
    min-width: 50px;
    display: inline-block;
    text-align: right;
     float: right;
    /*display: inline-block;*/
    /*width: 100%;*/
    /*vertical-align: top;*/
}
[data-sign="plus"] {
    color: #074907;
}
[data-sign="minus"] {
    color: #720606;
}
.rubDetails.woPull {
    min-width: 80px;
    float: none;
    text-align: left;
}

.custom-scroll {
    max-width: 100%;
    overflow-x: auto; /* Enables horizontal scrolling */
}

.korpItem.big {
    width: 120px
}
.korpItem {
    width: 70px;
    display: inline-block;
}

.paddTr {
    padding: 0 3px;
    max-width: 100%;
}

.paddTr .fa {
    padding-right: 5px;
    margin-top: 2px;
    float: left;
}
.emptyWrapParent > div {
    display: inline-block;
    padding: 0 5px;
}

.transItem:hover{
    box-shadow: 5px 5px 5px 5px rgba(128, 128, 128, 0.32);
}
.transItem {
    transition: all .4s ease;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px;
    border: 1px solid rgba(128, 128, 128, 0.07);
}
.MuiAutocomplete-root {
    margin-top: 0;

}

.react-datepicker-wrapper {
    width: 100%;
}

.labelAutoComplete {
    font-size: 10px;
    margin-bottom: -10px;
    margin-top: 8px;
}
.perevod-title {
    padding: 15px 0 10px 0;
    font-weight: bold;
}
.favStars {
    padding: 0;
    margin: 5px 5px 0 20px;
}
.favStars .fa {
    font-size: 20px;
}

.tr {
    text-align: right;
}



.wrapTrBlock {
    padding: 10px;
    border: 1px solid rgba(211, 211, 211, 0.48);
    margin: 5px 0;
    font-size: 14px;
}


.overflowAuto {
    overflow: auto;
}


.periodPlusMinus {
    padding: 2px;
    font-size: 12px;
}
.payProblem {
    padding: 5px 0;
    margin-bottom: 3px;
    background: #eebbbb59;
    border: none!important;
}
.payProblem.hov:hover {
    background: #efc9c9;
}