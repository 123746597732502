.App {
    text-align: center;
}
.sactive {
    background: rgba(144, 238, 144, 0.29);
}
.messages {
    max-height: 300px;
    overflow: auto;
}
.ellipseTemplate {
    max-height: 46px;
    border: 1px solid lightgrey;
    overflow: hidden;
    font-size: 11px
}
.message-item {
    border-bottom: 1px solid lightgrey;
    margin-bottom: 3px;
    padding-bottom: 3px;
}
.forChats {
    height: calc(100vh - 125px)
}
.forChats > div {
    max-height: calc(100vh - 120px);
    overflow-y: auto;
    overflow-x: hidden;
}
.MuiAutocomplete-root {
    max-width: 100%;
}
.arr-item:hover .drag-handle, .arr-item:hover .fa-close {
    opacity: .7;
}
.client_message pre {
    background: rgba(230, 252, 230, 1);
}
.first_message {
    padding: 0;
    opacity: .8;
    max-height: 150px;
    min-height: 50px;
    overflow: auto;
    border: 2px solid blue;
}
.unread {
    border-right: 5px solid blue;
}
.declined_dialog::after {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 0, 0, 0.17);
}
.ellipses {
    display: block;
    width: 100%;           /* Set a fixed width or max-width */
    white-space: nowrap;    /* Prevents the text from wrapping to the next line */
    overflow: hidden;       /* Hide the text that overflows the container */
    text-overflow: ellipsis;/* Add ellipsis for the overflowed text */

}
.Mui-focused label {
    opacity: 0;
}
.arr-item .drag-handle, .arr-item .fa-close {
    transition: all .4s ease;
    opacity: .05;
}
.MuiInputBase-input.MuiOutlinedInput-input {
    box-shadow: none;
}
.MuiOutlinedInput-notchedOutline {
    border: 0!important;
    border-radius: 0!important;
    border-top: 0 solid transparent;
    border-left: 0 solid transparent;
    border-right: 0 solid transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.comps-list-detailed .drag-handle,
.comps-list-detailed .fa-close {
    margin-top: 20px !important;
}

.delta-input, .fhours-input {
    width: 70px;
    margin: 0;
    margin-left: -3px;
    border: none;
    border-bottom: 1px solid grey;
    border-radius: 0;
    padding: 3px;
    height: 20px;
    text-align: center;
}

.big-input:focus {
    border: none;
    border-bottom: 1px solid grey;
}

.big-input {
    outline: none;
    border: none;
    border-bottom: 1px dashed #f5f5f5;
    width: 100%;
    font-size: 35px;
    text-align: left;
    padding: 25px 15px;
    margin-top: 5px;
    margin-bottom: 20px;
}

.customization {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.comp-vars.delta {
    margin-left: 15px;
}

.comp-img[data-key="delta"] {
    /*width: 15px;*/
    text-align: center;
    margin: 0 auto;
}

.comp-icons-block {
    margin-right: 5px;
}

.comp-img[data-key="fhours"] {
    /*width: 15px;*/
}

.w100 {
    width: 100%;
}

.comp-img {
    max-height: 100%;
    width: 20px;
}

.searcher-button {
    margin-top: 30px;
}

.child-non-selected .child-checkbox {
    /*padding-top: 7px;*/
}

.child-checkbox {
    /*padding-top: 12px;*/
    padding-right: 5px;
    margin-top: -10px;
    margin-left: 5px;
}

.cust-child {
    padding-top: 16px;
}

body {
    padding-left: 15px;
}

.small-title {
    font-size: 12px;
}

.child-name {
    /*padding-top: 16px;*/
}

.customization .cust-details {
    padding-left: 5px;
}

.passive:focus {
    opacity: 1;
}

.comp-vars, .passive {
    opacity: .1;
    transition: all .4s ease;
}

.passive {
    opacity: .4;
}

.comp-vars:hover {
    opacity: .5;
}

.comp-vars.active, .comp-vars.active:hover {
    /*color: #7a6fbe;*/
    text-decoration: underline;
    font-weight: bold;
    opacity: .7;
    transition: all .4s ease;
    /*background: #e5daf6;*/
}
.count-input {
    width: 30px!important;
    margin-left: 10px;
    margin-right: -15px;
}
.mt-10 {
    margin-top: 10px;
}

.sort-item {
    border-left: 4px solid transparent;
}

.sort-item.active {
    /*background: rgb(248, 246, 255);*/
    border-left: 4px solid black;
}

.custom-child {
    padding-left: 45px;
    width: 100%;
    /*position: relative;*/
}

/*.cust-details {*/
/*  display: block;*/
/*}*/
.preventClick::before {
    position: absolute;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.bold {
    font-weight: bold;
}

.top-links a {
    margin-right: 15px;
}

.pub-hours {
    font-size: 25px;
}

.ml-10 {
    margin-left: 10px;
}

.mr-10 {
    margin-right: 10px;
}

iframe {
    width: 100%;
    height: 1000px;
}

.empty {
    opacity: .2
}
.comp-vars {
    margin-right: 5px;
    cursor: pointer;
    /*font-weight: bold;*/
}

.hours {
    /*color: lightgrey;*/
    font-size: 12px;
    display: block;
    /*content: 'h';*/
    /*font-size: 10px;*/
}

.MuiAutocomplete-popper > * {
    z-index: 10000 !important;
    /*background: red;*/
}

body {
    background: #F5F5F5;
    font-size: 16px;
}

.mainCont, .headerCont {
    max-width: 1400px;
    margin: 0 auto;
}

.no-padd {
    padding-top: 0 !important;
}

.main-score span {
    margin-right: 20px;
}

.main-score {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;

}
.small-it {
    display: inline-block;
    padding: 0 2px;
}
.back-link {
    padding-bottom: 15px;
    display: block;
}

.mainCont {
    background: #FFFFFF;
    box-shadow: -5px 1px 15px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding: 20px;
}
#root {
    padding-bottom: 15px;
}
.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.ib {
    display: inline-block;
    vertical-align: top;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

body {
    color: #323232;
}

input[type="checkbox"] {
    max-width: 15px;
}

.pointer {
    cursor: pointer;
}
.ml5 {
    margin-left: 5px;
}
.mr5 {
    margin-right: 5px;
}

.itemEdit {
    box-shadow: 0px 0px 5px 5px rgba(128, 128, 128, 0.31);
}

.labelAutoComplete {
    opacity: .4;
}
.inputlabel {
    opacity: .4;
    font-size: 12px;
}
.payHover:hover {
    opacity: 1;
}
.payHover {
    opacity: .4
}

.ellipse {
    float: none;
}
.mr-5 {
    margin-right: 5px;
}

.shortPreview .payProblem {
    padding: 0!important;
    padding-top: 2px!important;
}
.shortPreview .transItem {
    margin-top: 1px;
    margin-bottom: 1px;
    padding: 3px 5px 0 5px;
}